<template>
  <div>
    <SectionHeader title="Price postition" title-icon="fal fa-tags" />
    <div class="box">
      <div v-if="pricePositionPercentages">
        <DonutChart
          :series="(breakdown || []).map(({ count }) => count)"
          :labels="(breakdown || []).map(({ label }) => label)"
          total-label="Vehicles"
        />
        <PercentageBreakdown v-bind="{ breakdown }" @show-stock="showStock" />
      </div>
      <TableEmpty v-else text="No data found for your branch" size="small" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { getBarValues, getBreakdown } from '../services'
export default {
  name: 'StockviewPricePosition',
  components: {
    PercentageBreakdown: () => import('./StockviewPercentageBreakdown'),
    TableEmpty: () => import('core/components/TableEmpty'),
    SectionHeader: () =>
      import('core/modules/section-header/components/SectionHeader'),
    DonutChart: () => import('core/components/graph/DonutChart')
  },
  computed: {
    ...mapGetters('stockview/dashboard', [
      'pricePositionCount',
      'pricePositionPercentages'
    ]),
    values() {
      const keyMap = {
        changePrice: 'Needs action now',
        considerReview: 'Consider action',
        pricedWell: 'Priced well'
      }

      return getBarValues(this.pricePositionPercentages, keyMap)
    },
    breakdown() {
      const labels = {
        changePrice: 'Needs action now',
        considerReview: 'Consider action',
        pricedWell: 'Priced well',
        notAvailable: 'Not available'
      }

      return getBreakdown(
        this.pricePositionCount,
        this.pricePositionPercentages,
        labels
      )
    }
  },
  methods: {
    ...mapActions('stockview/listings', ['reset', 'updateFilters']),
    showStock(identifier) {
  this.reset()

  const type = 'pricePosition'
  const map = {
    changePrice: ['UnderPriced', 'OverPriced'],
    considerReview: ['ConsiderReview'],
    pricedWell: ['PricedWell']
  }
  const filters = [{ type, payload: map[identifier] }]

  this.updateFilters({ filters, count: map[identifier].length, type })

  this.$router.push({ name: 'stockview-stock' })
}
  }
}
</script>
  